<template>
	<div class="product-list">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item>搜索结果</el-breadcrumb-item>
			</el-breadcrumb>
			<!-- <MainSearch @onSearch="handleSearch" @changeSearch="changeSearch" @blurSearch="blurSearch"
			:keyword="query.keyword" :searchData="searchData" :resultShow="resultShow"></MainSearch> -->
			<el-table v-loading="loading" :data="tableData" class="main-table" header-row-class-name="table-header" cell-class-name="table-hover" :row-class-name="tableRowClassName" ref="table"
				@sort-change="handleSort" highlight-current-row @row-click="handleRowClick" v-if="tableShow">
				<el-table-column label="序号" type="index" :index="indexMethod" width="80" align="center">
				</el-table-column>
				<el-table-column prop="buyCode" label="订货备件号"></el-table-column>
				<el-table-column prop="name" label="备品名称"></el-table-column>
				<el-table-column prop="priceEndUser" label="市场指导价(不含税)" align="center"></el-table-column>
                <el-table-column prop="servicePrice" label="服务站价(不含税)" align="center">
                    <template slot-scope="scope">
                        <div v-for="(item, index) in servicePrice(scope.row)" :key="index">
                            <span>{{item.name}} : {{item.val}}</span>
                        </div>
                    </template>
                </el-table-column>
				<el-table-column prop="qty" label="库存数量" sortable="custom" align="center"></el-table-column>
				<!-- <el-table-column prop="updateTime" label="修改时间" width="110" align="center" sortable="custome">
					<template slot-scope="scope">
						<p v-if="scope.row.updateTime">{{scope.row.updateTime.split(' ')[0]}}</p>
						<p v-if="scope.row.updateTime">{{scope.row.updateTime.split(' ')[1]}}</p>
					</template>
				</el-table-column> -->
				<el-table-column label="操作" width="140" align="center" class-name="table-operate">
					<template slot-scope="scope">
						<el-button class="operation-btn" type="warning" size="small" title="资料修改"
							@click="goDetail(scope.row)">详细参数</el-button>
						<!-- <el-button v-if="!power('edit') && !power('price-edit') && !power('verify-edit')"
							class="square-btn edit-btn" type="warning" size="small" title="资料修改"
							@click="goDetail(scope.row)">资料<br />查看</el-button> -->
						<!-- <el-button class="square-btn" v-if="power('del')" type="danger" size="small" title="删除"
							@click="deleteClick(scope.row)">删除</el-button> -->
					</template>
				</el-table-column>
			</el-table>
			<el-pagination background layout="prev, pager, next" :total="this.total" :current-page.sync="query.page"
				:page-size="query.pagesize" @current-change="getList" v-if="tableShow">
			</el-pagination>
            <el-checkbox v-model="query.showPic" @change="changeShow">显示有图片备件</el-checkbox>
            <div><el-link @click="handleDownload" v-loading="downloadLoading">导出</el-link></div>

    </div>
</template>

<script>
	import http from "../../http.js";
	import power from "../../power.js";
	import commFunc from "@/commFunc";
    import {
        isLightTruck,
        isHeavyTruck,
        isJiefang,
        calcLightTruckPrice, calcHeavyTruckPrice, calcFawPrice
    } from "../../common/calcAllServicePrice";
	// import MainSearch from "../../components/search.vue";
	export default {
		name: "Home",
		components: {
			// MainSearch,
		},
		data() {
			return {
                loading: false,
                downloadLoading: false,
				resultShow:false,
				tableShow: true,
				inputValue: '',
				query: {
					page: 1,
					pagesize: 10,
					keyword: "",
					model: "",
					sort: "-id",
                    showPic: ''
				},
				total: "",
				tableData: [],
				searchData:[],
				loginKey: 0,
			};
		},
		created() {
			this.query.keyword = this.$route.query.keyword;
			this.init();
		},
		methods: {
            brandFormat(row) {
                if(isLightTruck(row.stores)){
                    return "青汽青卡"
                }else if(isHeavyTruck(row.stores)){
                    return "青汽重卡"
                }else if(isJiefang(row.stores)){
                    return "一汽解放"
                }else{
                    return '-'
                }
            },
            servicePrice(row) {
                let price = []
                if(isLightTruck(row.stores)){
                    price.push({
                        name: "青汽轻卡",
                        val: calcLightTruckPrice(row)
                    })
                }
                if(isHeavyTruck(row.stores)){
                    price.push({
                        name: "青汽重卡",
                        val: calcHeavyTruckPrice(row)
                    })
                }
                if(isJiefang(row.stores)){
                    price.push({
                        name: "一汽解放",
                        val: calcFawPrice(row)
                    })
                }
                if(price.length == 0){
                    price.push({
                        name: "-",
                        val: "-"
                    })
                }
                return price
            },
			tableRowClassName({rowIndex}) {
				if (rowIndex % 2 == 0) {
					return "";
				} else {
					return "active-row";
				}
			},
			rateDiv100(item) {
				commFunc.rateDiv100(item)
			},
			rateMul100(item) {
				commFunc.rateMul100(item)
			},
			sureClick() {
				this.$confirm('确定要修改吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$message({
						type: 'success',
						message: '修改成功!'
					});
				}).catch(() => {

				});
			},
			// 收起
			saveClick(row) {
				this.$confirm('确定要保存吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let $table = this.$refs.table;
					console.log(row)
					this.saveProduct(row, $table)
					// $table.toggleRowExpansion(row)
				}).catch(() => {

				});
			},
			saveProduct(p, $table) {
				this.rateDiv100(p);
				//保存
				http.fetchPost("/api/admin/product", p).then((res) => {
					if (res.data.code == 0) {
						$table.toggleRowExpansion(p, false)
						this.$message({
							type: 'success',
							message: '保存成功!'
						});
						this.$message.success("保存成功");
						this.getList()
					} else {
						this.$message.error(res.data.desc);
					}
				});
			},
			// 展开
			toogleExpand(row) {
				let $table = this.$refs.table;
				console.log(row)
				$table.toggleRowExpansion(row)
				// $table.toggleRowExpansion(row, true) // 点击button展开
			},
			power(p) {
				return power(p);
			},
			handleLogin() {
				this.init();
				// window.location.reload()
			},
			handleSearch(keyword) {
				this.query.keyword = keyword;
				this.query.page = 1;
				this.getList();
				this.resultShow=false
				this.tableShow = true
			},
			init() {
				this.getList();
			},
			getList() {
                this.loading = true
				http.fetchGet("/api/admin/product/alllist", this.query).then((res) => {
					if (res.data.code == 0) {
						let list = res.data.data.list
						// list.forEach(item => {
						// 	this.rateMul100(item);
						// })
						this.tableData = list;

						this.total = res.data.data.total;
					} else if (res.data.code == 50008) {
						localStorage.clear();
						this.loginKey++;
						this.$message.error("登录信息已失效，请重新登录");
					}
				}).finally(() => {
                    this.loading = false
                });
			},
			changeSearch(keyword){
				this.query.keyword = keyword;
				this.query.page = 1;
				http.fetchGet("/api/admin/product/alllist", this.query).then((res) => {
					if (res.data.code == 0) {
						let list = res.data.data.list
						list.forEach(item => {
							this.rateMul100(item);
						})
						this.searchData = list;
						this.total = res.data.data.total;
						if(this.searchData.length==0){
							this.resultShow=false
						}else{
							this.resultShow=true
						}
					} else if (res.data.code == 50008) {
						localStorage.clear();
						this.loginKey++;
						this.$message.error("登录信息已失效，请重新登录");
					}
				});

			},
			// blurSearch(){
			// 	this.resultShow=false
			// },
			editClick(item) {
				if (item) {
					this.$router.push("/sparesDetails?id=" + item.id);
				} else {
					this.$router.push("/sparesDetails");
				}
			},
			goDetail(row) {
                const detail = this.$router.resolve({name: 'sparesView', query: {id: row.id}})
                window.open(detail.href,'_blank')
			},
			// deleteClick() {
			// 	this.$confirm("确定要永久删除该行备品吗?", "提示", {
			// 			confirmButtonText: "确定",
			// 			cancelButtonText: "取消",
			// 			type: "warning",
			// 		})
			// 		.then(() => {
			// 			this.$message({
			// 				type: "success",
			// 				message: "删除成功!",
			// 			});
			// 		})
			// 		.catch(() => {
			// 			this.$message({
			// 				type: "info",
			// 				message: "已取消删除",
			// 			});
			// 		});
			// },
			handleResetSearch() {
				this.query.page = 1
				this.query.keyword = ''
				this.query.model = ''
				this.query.category = ''
				this.brandItem = 0
				this.categoryItem = 0
				this.classItem = 0
				this.getList()
			},
			handleSort(e) {
				console.log(e);
				if (e.order == "ascending") {
					this.query.sort = "+" + e.prop
				} else if (e.order == "descending") {
					this.query.sort = "-" + e.prop
				} else {
					this.query.sort = ""
				}
				this.query.page = 1
				this.getList()
			},
			handleDownload() {
				this.downloadLoading = true
				let _query = JSON.parse(JSON.stringify(this.query))
                _query.page = 1
				_query.pagesize = 999999
				http.fetchGet("/api/admin/product/alllist", _query).then((res) => {
					if (res.data.code == 0) {
						let list = res.data.data.list
						import('@/vendor/Export2Excel').then(excel => {
							const tHeader = ['订货备件代码', '备件名称']
							const filterVal = ['buyCode', 'name']
							const data = this.formatJson(filterVal, list)
							excel.export_json_to_excel({
								header: tHeader,
								data,
								filename: '备品列表',
								autoWidth: true,
								bookType: 'xlsx'
							})
							this.downloadLoading = false
						})
					}
				});
			},
			formatJson(filterVal, jsonData) {
				return jsonData.map(v => filterVal.map(j => {
					return v[j]
				}))
			},
			priceChange(p) {
				this.$nextTick(() => {
					commFunc.priceCalc(p)
					this.$forceUpdate();
				});
			},
			indexMethod(index) {
				return (this.query.page - 1) * this.query.pagesize + index + 1;
			},
			handleRowClick(row){
				this.goDetail(row)
			},
            changeShow(){
                this.query.page = 1
                this.getList()
            },
		},
	};
</script>
