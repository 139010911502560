//判断是否为青汽轻卡
export function isLightTruck(stores) {
    let lightTruckStoreList = ['QKDBK', 'QKDBK-JL', 'QKHBK', 'QKHBK-JN', 'QKHBK-NM', 'QKHDLYK',
        'QKHDZBK', 'QKHNK', 'QKHNK-FJ', 'QKHZHZK', 'QKHZWHK', 'QKWHK-JX', 'QKXBK', 'QKXNK',
        'QKXNK-YN', 'QKZBK-HN']
    let isLightTruck = false
    if(stores && stores.length > 0) {
        stores.forEach(store => {
            if(lightTruckStoreList.indexOf(store.storeNo) > -1) {
                isLightTruck = true
            }
        })
    }
    return isLightTruck
}

//判断是否为重卡
export function isHeavyTruck(stores){
    let heavyTruckStoreList = ['BWDBK', 'BWHBK', 'BWHDK', 'BWHDZBK', 'BWHNK', 'BWHZHZK',
        'BWHZK', 'BWHZWHK', 'BWXBK', 'BWXNK']
    let isHeavyTruck = false
    if(stores && stores.length > 0) {
        stores.forEach(store => {
            if(heavyTruckStoreList.indexOf(store.storeNo) > -1) {
                isHeavyTruck = true
            }
        })
    }
    return isHeavyTruck
}

//判断是否为解放
export function isJiefang(stores){
    let jiefangStoreList = ['B5016', 'BDZB', 'BFZB', 'BLZB', 'BMZB', 'BNA3', 'BNJG', 'BPSDBZ',
        'BPSDBZ01', 'BPSDDY', 'BPSDDZ', 'BPSDDZ01', 'BPSDGM', 'BPSDHZ', 'BPSDHZ1', 'BPSDHZ2',
        'BPSDJN',
        'BPSDJN01',
        'BPSDJN02',
        'BPSDJN03',
        'BPSDJN04',
        'BPSDJN05',
        'BPSDJN06',
        'BPSDJN07',
        'BPSDJZ',
        'BPSDLC',
        'BPSDLC01',
        'BPSDLK',
        'BPSDLS',
        'BPSDLW',
        'BPSDLY',
        'BPSDLY01',
        'BPSDLY02',
        'BPSDLY03',
        'BPSDMY',
        'BPSDQD',
        'BPSDQD01',
        'BPSDQD02',
        'BPSDQX',
        'BPSDQZ',
        'BPSDRC',
        'BPSDRZ',
        'BPSDSG',
        'BPSDTZ',
        'BPSDTZ1',
        'BPSDWF',
        'BPSDWF01',
        'BPSDYG',
        'BPSDYT',
        'BPSDZB',
        'BPSDZB01',
        'BPSDZBLC',
        'BPSDZZ',
        'BPZB01',
        'BQZB',
        'BSZB',
        'BTZB',
        'BWLY',
        'BWZB',
        'BWZB01',
        'BXZB',
        'BYZB',
        'CPZB',
        'CYZB',
        'CYZB01'
    ]
    let isJiefang = false
    if(stores && stores.length > 0) {
        stores.forEach(store => {
            if(jiefangStoreList.indexOf(store.storeNo) > -1) {
                isJiefang = true
            }
        })
    }
    return isJiefang
}

export function calcLightTruckPrice(product){
    let rate1_01 = ['安徽全柴*WMS', '潍柴动力', '大连柴油', '无锡柴油', '（云内）济南贵凯龙(请索赔LYB24)',
        '行远智赢', '全柴顺兴(请索赔LJB05)*WMS']
    let rate1_04 = ['青岛车桥', '解放传动*WMS', '青岛厂', '一汽变速']
    let rate1 = ['油品']
    if (rate1_01.indexOf(product.supplierName) > -1) {
        return (product.priceEndUser * 1.01).toFixed(2)
    }else if(rate1_04.indexOf(product.supplierName) > -1){
        return (product.priceEndUser * 1.04).toFixed(2)
    }else if(product.stores.findIndex(e=>e.storeAreaName == rate1) > -1){
        return product.priceEndUser
    }else{
        return (product.priceEndUser * 1.02).toFixed(2)
    }
}

export function calcHeavyTruckPrice(product){
    return product.priceEndUser
}

export function calcFawPrice(product){
    return (product.priceEndUser * 0.95).toFixed(2)
}

export function calcServicePrice(row){
    if(isLightTruck(row.stores)) {
        return calcLightTruckPrice(row)
    }else if(isHeavyTruck(row.stores)){
        return calcHeavyTruckPrice(row)
    }else if(isJiefang(row.stores)){
        return calcFawPrice(row)
    }else{
        return '-'
    }
}
